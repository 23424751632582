// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../styles/landkit/index.js';
import '../images';
//import '../channels';
//import '../controllers';


require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();


import 'bootstrap/dist/js/bootstrap.js';

import 'aos/dist/aos.js';

const flatpickr = require('flatpickr');
const inputSpinner = require('bootstrap-input-spinner')
import 'assets/js/landkit/theme.min.js';
import '../landkit/js/customization.js';


