import 'assets/fonts/Feather/feather.css';
import 'flickity/dist/flickity.min.css';
// import 'flickity-fade/flickity-fade.css';
import 'aos/dist/aos.css';
// import 'jarallax/dist/jarallax.css';
import 'flatpickr/dist/flatpickr.min.css';
// import 'highlightjs/styles/vs2015.css';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import 'assets/libs/fotorama-4.6.4/fotorama.css';
import 'assets/stylesheets/landkit/theme.min.css';
import '../landkit.css';

import './customization.css';
import './mobile_video_frame.css';
