$(document).on("turbolinks:load", function() {
  // hide penalty rate field
  $('.penalty-checkbox').click(function() {
    $('.penalty-rate-group').toggle(this.checked);
  });

  $('.penalty-checkbox').each(function(i, checkBox) {
    if (!checkBox.checked) {
      $('.penalty-rate-group').hide();
    }
  });

  // hide contract valid until field
  $('.open-ended-contract-checkbox').click(function() {
    $('.contract-valid-until-group').toggle(!this.checked);
  });

  $('.open-ended-contract-checkbox').each(function(i, checkBox) {
    if (checkBox.checked) {
      $('.contract-valid-until-group').hide();
    }
  });

  // initialze flatpickr calendar on .flatpickr class textbox
  $(".flatpickr").flatpickr( {
    dateFormat: "Y-m-d"
  });

  // add + - buttons to indeger inputs
  $(".with-input-spinner").inputSpinner();

  setTimeout(function() {
    $('#loginModal').modal({
      backdrop: 'static',
      show: true,
      keyboard: false
    });
  }, 2000);

  $(".alert").delay(15000).slideUp(1000, function(){
    $(".alert").alert('close');
  });
});
